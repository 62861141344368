.name {
    color: white;
    font-size: xx-large;
    margin-left: 10%;
    margin-top: 10%;
}

.homeContent {
    padding-top: 100px;
    padding-bottom: 100px;
    margin: auto;
    width: 75%;
    display: flex;
    flex-direction: row;
    text-align: justify;
}
