.info {
    display: flex;
    flex-direction: row;
    padding-top: 150px;
    margin: auto;
    width: 75%;
}

.hello {
    font-size: 400%;
    color: lightblue;
    outline: 1px black;
}

.right {
    padding-left: 50px;
    width: 40%;
}

.header {
    font-size: 200%;
    color: lightblue;
    background: none;
    border: 1px lightgray;
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 30px;
    padding: 10px ;
}

.social {
    font-size: large;
    color: black;
    display: block;
    margin: 10px;

}

.description {
    font-size: medium;
    line-height: 1;
    background-color: aliceblue;
    margin-bottom: 50px;
    padding: 20px;
    border: 1px lightgray;
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.intro {
    margin-top: 50px;
    line-height: 2;
}

.hidden {
    display: none;
}

.left {
    width: 70%;
}

p {
    font-size: large;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

ul {
    list-style-type: none;
    font-size: x-large;
    text-align: center;
}

li {
    margin: 10px;
    display: inline-block;
}

button {
    background: none;
    border: 1px lightgray;
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}