@import "rsuite/dist/rsuite.css";

:root {
    --cornflower-blue: #797EF6;
    --viking: #4adead;
    --picton-blue: #1AA7EC;
    --jacksons-purple: #1E2F97;
    --background-color: #003F5D;
    --shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bgimage {
  background-color: var(--background-color);
  height:max-content;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}


.portrait {
  max-width: 50%;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
